<template>
    <div class="items">
        <div class="container mt-5">
            <div class="row justify-content-center">
                <div class="col-md-12">
                    
                    <div class="card">
                        <div class="card-header">
                            Transaksi Barang Masuk
                        </div>
                          path :{{ path1 }} <br/>
                          sidebar :{{ sidebar }}
                         <div>
                            <a href="#" v-on:click.prevent="showSuccessToast">Display toast</a>
                        </div>
                        <div class="card-body">
                            
                            <router-link :class="['btn btn-md btn-success mb-2']" to="/tran_atk_in/create">TAMBAH TRANSAKSI</router-link> 
                            <hr>  
 
                            <div class="table-responsive mt-2">
                                <table class="table table-hover table-bordered">
                                    <thead>
                                    <tr>
                                        <th>KODE</th>
                                        <th>NAMA</th>
                                        <th>KET</th>
                                        <th>AKSI</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    
                                    <tr v-for="item in items" :key="item.id">
                                        <td>{{ item.kode }}</td>
                                        <td>{{ item.nama }}</td>
                                        <td>{{ item.ket }}</td>
                                        <td class="text-center">
                                            <!-- <router-link :to="{name: 'edit', params: { id: item.id }}" class="btn btn-sm btn-primary mr-2">EDIT</router-link> -->
                                            <router-link class="btn btn-sm btn-primary mr-2" :to="'/set_item/edit/' + item.id ">Edit</router-link>
                                            <button @click.prevent="itemDelete(item.id)" class="btn btn-sm btn-danger">HAPUS</button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                items: [],
                name : this.$route.name,
                menu : this.$route.meta.menu,
                path : this.$route.path,
                sidebar : this.$router.options.routes,
                path1 : this.$router.currentRoute.path,
            }
        },
        created() {
            var headers = { 
                "Access-Control-Allow-Origin": "*" 
            } 
            /* var headers= {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, item, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
            } */
            axios.get('http://localhost:8000/api/items', {}, headers).then(response => {
                this.items = response.data.data;
               // console.log(this.items);
            });
            
        },
         methods: {
            itemDelete(id)
            {
                axios.delete(`http://localhost:8000/api/items/${id}`)
                    .then(response => {
                        this.items.splice(this.items.indexOf(id), 1);
                        console.log(response);
                    }).catch(error => {
                    console.log(error.response);
                });
            },
             showSuccessToast() {
                    //this.$toastr.s("Success", "Hello world");
                    //this.$swal("Success", "Hello world");
                    this.$swal({
                        title: 'What is your Name?',
                        input: 'text',
                        border: '1px solid #F0E1A1',
                        //background: 'rgba(43, 165, 137, 0.45)'
                        backdrop: "rgba(43, 165, 137, 0.45)",
                        //background: '#4962B3'
                        //background: "black",
                        //backdrop: "linear-gradient(yellow, orange)",
                      
                        });
                     
                    /* this.$swal({
                        title: 'What is your Name?',
                        input: 'text',
                        //background: "black",
                        //backdrop: "linear-gradient(yellow, orange)",
                        inputPlaceholder: 'Enter your name here',
                        showCloseButton: true,
                        }); */
                 }
        }
    }
</script>